.block__categories {
  margin-bottom: 42px;
}

.block__search {
  margin-bottom: 25px;
}

.block__filter {
  margin-bottom: 20px;
}

.block__wrapper {
  display: flex;
}

.block__title {
  display: flex;
  margin-bottom: 40px;
}

.block__order-status {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.block__order-info {
  margin-bottom: 40px;
}

.block__button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.block__order-list {
  margin-bottom: 40px;
}

.block__options {
  margin-bottom: 40px;
}

.block__title-order {
  margin-bottom: 40px;
}
