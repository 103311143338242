.input {
  padding: 11px 18px;
  border-radius: 20px;
  background-color: $default-white;
  color: $default-black;
  border: none;
  transition: $transition;
  width: 120px;
  margin-right: 8px;
  font-size: 14px;

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $focus-color;
  }

  &--filter {
    font-size: 10px;
    line-height: 13px;
    text-transform: uppercase;
    padding: 10px 18px 9px 18px;
  }

  &--id {
    width: 148px;
  }

  &--ozon {
    width: 120px;
  }

  &--large {
    width: 248px;
  }

  &--search {
    width: 318px;
  }

  &--params {
    border: 1px solid rgba(138, 140, 155, 0.2);
    border-radius: 4px;
    padding-left: 8px;
    font-size: 11.5px;

    &:disabled {
      background-color: transparent;
      cursor: pointer;
    }
  }
}

