.dropdown__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.Dropdown-option {
  background-color: $default-white;
  border-radius: 20px;
  position: relative;
  display: flex;
  margin-right: 8px;
  margin-bottom: 8px;
}

.dropdown__button {
  position: relative;
  border: none;
  background-color: $default-white;
  border-radius: 20px;
  padding: 9px 30px 10px 18px;
  font-size: 10px;
  line-height: 13px;
  max-height: 32px;
  color: $default-transparentgray;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  transition: $transition;
  cursor: pointer;
  min-width: 160px;
  margin-bottom: 8px;

  &:after {
    content: '';
    position: absolute;
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    top: 9px;
    right: 15px;
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px $focus-color;
  }
}

.dropdown__close {
  background-color: $default-lightgray;
  border: none;
  border-radius: 0 20px 20px 0;
  margin-left: auto;
  right: 0;
  width: 32px;
  height: 32px;
  font-size: 26px;
  font-weight: 300;
  transition: $transition;
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: $default-black;
    color: $default-white;
  }
}
