.quantity {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: $input-gray;
  border-radius: 22px;
  width: 120px;
  border: 4px solid $input-gray;
}

.quantity__plus,
.quantity__minus {
  border: 1px solid $default-black;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: $default-white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  transition: $transition;

  &:hover {
    background-color: $default-black;
    color: $default-white;
  }
}

.quantity__input {
  width: 52px;
  height: 32px;
  font-size: 11.5px;
  background-color: $input-gray;
  color: $default-gray;
  border: none;
  text-align: center;
}

.quantity,
.quantity button,
.quantity input {
  &:focus {
    outline: none;
  }
}
