@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/reset";

html {
  line-height: $mainLineHeight;
	font-size: $mainFontSize;
	color: $mainFontColor;
  height: 100%;
  @media screen and (max-width: $desktopWidth) {
		font-size: 16px;
  }
  @media screen and (max-width: $tableWidth) {
		font-size: 15px;
  }
  @media screen and (max-width: $phoneWidth) {
		font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
	font-size: $mainFontSize;
  font-weight: $mainFontWeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $main-bg;
  font-family: 'Tondo Corp', Verdana, sans-serif;
}

*, *:before, *:after { box-sizing: border-box; }

h1, h2, h3, h4, h5, h6 { margin: 0; }

a { text-decoration: none; }

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

section, .section {
  position: relative;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-height: 100vh;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  width: 100%;
  flex: 0 0 auto;
  position: relative;
  margin: 0 auto;
  max-width: $desktopWidth;

  &--wide {
    max-width: $desktopWideWidth;
  }

  @media screen and (max-width: $smDesktopWidth) {
    max-width: tableWidth;
  }
  @media screen and (max-width: $tableWidth) {
    max-width: phoneWidth;
  }
  @media screen and (max-width: $phoneWidth) {
    max-width: smPhoneWidth;
  }
}

.section-main {
  margin: 40px 0;
}

.section-half {
  &:first-of-type {
    width: 512px;
    margin-right: 16px;
  }

  &:last-of-type {
    width: 612px;
    margin-right: 0;
  }
}

// Modules
// @import "modules/my-module.scss";

@import "modules/header";
@import "modules/goods";
@import "modules/block";
@import "modules/btn";
@import "modules/title";
@import "modules/input";
@import "modules/dropdown";
@import "modules/table";
@import "modules/arrow-btn";
@import "modules/dots-btn";
@import "modules/checkbox";
@import "modules/switch";
@import "modules/upload";
@import "modules/pagination";
@import "modules/payment";
@import "modules/order-info";
@import "modules/order-list";
@import "modules/quantity";
@import "modules/options";
