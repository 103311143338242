// Font
$mainFont                : 'Tondo Corp', Helvetica, Arial, sans-serif;

// Size
$mainFontColor           : #515159;
$mainFontSize            : 16px;
$mainFontWeight          : 400;
$mainLineHeight          : 1.3;

$desktopWideWidth        : 1440px;
$desktopWidth            : 1140px;
$smDesktopWidth          : 980px;
$tableWidth              : 768px;
$phoneWidth              : 480px;
$smPhoneWidth            : 320px;

// Main color
$default-white           : #ffffff;
$default-black           : #000000;
$default-gray            : #515159;
$default-lightgray       : #dee2e9;
$default-transparentgray : #a8a8ac;
$main-bg                 : #f0f3f9;
$default-yellow          : #fff8e7;
$default-red             : #ff5c5c;

$pag-gray                : #8a8b99;
$input-gray              : #e5e8ef;
$bg-gray                 : #f7f9fd;
$dark-gray               : #d6dbe3;
$neutral-gray            : #dee2e9;
$bg-three-dots           : #c0c4cc;
$options-gray            : #8a8c9b;
$focus-color             : lightblue;
$table-hover-gray        : #e9edf3;
$table-hover-orange      : #ede6d4;

$transition              : all 200ms ease-out;
